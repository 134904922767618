import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { authClient } from '../api/apiClient';
import axios from 'axios';

export default function SetupPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSetupPassword = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const searchParams = new URLSearchParams(window.location.search);
      const email = searchParams.get('email');
      const token = searchParams.get('token');

      if (!token) {
        setError('No token provided.');
        return;
      }

      const response = await authClient.post('/setup-password', {
        email,
        token,
        password,
      });
      if (response.data.status) {
        alert('Password setup successful!');
        navigate('/login');
      } else {
        alert('Failed to set up password');
        throw new Error('Failed to set up password');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        const message =
          err.response?.data.message || 'Failed to set up password.';
        setError(message);
      } else {
        setError('An unexpected error occurred.');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Setup Your Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSetupPassword}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            autoComplete="new-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Set Password
          </Button>
          {error && (
            <Alert severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
}
