import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    customProperty: string;
  }
  interface ThemeOptions {
    customProperty?: string;
  }
}

const theme = createTheme({
  customProperty: 'customValue',
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

export default theme;
