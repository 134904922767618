const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ENDPOINTS = {
  AUTH: {
    LOGIN: `${AUTH_BASE_URL}/login`,
    LOGOUT: `${AUTH_BASE_URL}/logout`,
    REFRESH_TOKEN: `${AUTH_BASE_URL}/refresh-token`,
    VALIDATE_TOKEN: `${AUTH_BASE_URL}/validate-token`,
    FORGOT_PASSWORD: `${AUTH_BASE_URL}/forgot-password`,
    SETUP_PASSWORD: `${AUTH_BASE_URL}/setup-password`,
  },
  USERS: {
    GET_USER_BY_EMAIL: (email: string) =>
      `${AUTH_BASE_URL}/users/email/${email}`,
  },
};

export default ENDPOINTS;
