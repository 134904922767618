import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Homepage from './pages/homepage';
import Account from './pages/account';
import Login from './pages/login';
import IndividualResourcePage from './pages/individual-resource-page';
import AddResources from './pages/add-resources';
import InvitePage from './pages/invite-page';
import Stepper from './pages/stepper';
import SetupPassword from './pages/setup-password';
import PrivateRoute from './components/PrivateRoute';
import AdminPanel from './pages/admin-panel';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/setup-password" element={<SetupPassword />} />
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/account" element={<Account />} />
          <Route
            path="/individual-resource-page/:id"
            element={<IndividualResourcePage />}
          />
          <Route path="/add-resources" element={<AddResources />} />

          <Route path="/invite-page" element={<InvitePage />} />
          <Route path="/stepper" element={<Stepper />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
