import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { apiClient } from '../api/apiClient';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

export default function AddResourcePage() {
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [tags, setTags] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const { user } = useAuth();

  const navigate = useNavigate();

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleSummaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSummary(event.target.value);
  };

  const handleTagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTags(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('summary', summary);
    formData.append('tags', tags);
    formData.append('userRole', user?.role || 'user');
    formData.append('userId', user?.id || '');
    if (file) {
      formData.append('file', file);
    }
    try {
      const response = await apiClient.post('/resources/create', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (response.status === 201) {
        alert('Resource created successfully!');
        navigate('/');
      } else {
        throw new Error('Failed to save the resource');
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`Error saving the resource: ${error}`);
    }
  };

  return (
    <Box sx={{ width: '50%', margin: 'auto', paddingTop: 4 }}>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Add a New Resource
      </Typography>
      <TextField
        fullWidth
        label="Title"
        value={title}
        onChange={handleTitleChange}
        sx={{ marginBottom: 2 }}
      />
      <TextField
        fullWidth
        label="Summary"
        value={summary}
        onChange={handleSummaryChange}
        multiline
        rows={4}
        sx={{ marginBottom: 2 }}
      />
      <TextField
        fullWidth
        label="Tags (comma-separated)"
        value={tags}
        onChange={handleTagsChange}
        sx={{ marginBottom: 2 }}
      />
      <input
        accept="image/*,video/*,.pdf"
        style={{ display: 'none' }}
        id="upload-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          sx={{ marginBottom: 2 }}
        >
          Upload File/Video/Image
        </Button>
      </label>
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        {file ? `Selected file: ${file.name}` : 'No file selected'}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
}
