import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Alert,
} from '@mui/material';
import { ResourceStatusEnum } from '../core/enums/resource-status.enum';
import { Resource } from '../core/types/resource.type';
import shortenString from '../utils/shortenString';
import { listDrafts } from '../api/queries/listDrafts';
import { useAuth } from '../auth/AuthContext';
import { updateResourceStatus } from '../api/mutations/handleChangeToInReview';

export default function UserProfile() {
  const [resources, setResources] = useState<Resource[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [currentResourceId, setCurrentResourceId] = useState<string | null>(
    null,
  );
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const fetchUserDrafts = async () => {
      setLoading(true);
      setError('');
      setSuccess('');

      if (user) {
        try {
          const fetchedResources = await listDrafts();
          setResources(fetchedResources);
        } catch (error) {
          console.error('Error fetching drafts:', error);
          setError('Failed to fetch drafts.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserDrafts();
  }, [user]);

  const handleChangeToInReview = async (resourceId: string) => {
    setLoading(true);
    try {
      const updatedResource = await updateResourceStatus(
        resourceId,
        ResourceStatusEnum.INREVIEW,
      );

      setResources((prev) =>
        prev.map((resource) =>
          resource.id === resourceId ? updatedResource : resource,
        ),
      );
      setSuccess('Draft successfully moved to In Review.');
    } catch (error) {
      console.error('Error changing status:', error);
      setError('Failed to update the draft status.');
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  const handleOpenDialog = (resourceId: string) => {
    setCurrentResourceId(resourceId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentResourceId(null);
  };

  return (
    <Box sx={{ maxWidth: 700, mx: 'auto', my: 4 }}>
      <Typography variant="h4" gutterBottom>
        My Drafts
      </Typography>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">Summary</TableCell>
              <TableCell align="right">Tags</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources && resources.length > 0 ? (
              resources.map((resource) => (
                <TableRow
                  key={resource.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                  hover
                >
                  <TableCell>{shortenString(resource.title, 20)}</TableCell>
                  <TableCell align="right">
                    {shortenString(resource.summary, 50)}
                  </TableCell>
                  <TableCell align="right">
                    {resource.tags?.join(', ') || 'No tags'}
                  </TableCell>
                  <TableCell align="right">
                    {resource.status === ResourceStatusEnum.DRAFT && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleOpenDialog(resource.id)}
                      >
                        Move to In Review
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No drafts found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to move this draft to In Review?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleChangeToInReview(currentResourceId!)}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
