import { apiClient } from '../apiClient';
import { Resource } from '../../core/types/resource.type';

export const listDrafts = async () => {
  try {
    const response = await apiClient.get<Resource[]>('/resources/list-draft');
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch drafts');
    }
  } catch (error) {
    console.error('Error fetching drafts:', error);
    throw new Error('Failed to fetch drafts.');
  }
};
