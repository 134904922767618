import { authClient } from '../apiClient';
import ENDPOINTS from '../endpoints';

export const setupPassword = async (
  email: string,
  password: string,
  token: string,
): Promise<void> => {
  await authClient.post(ENDPOINTS.AUTH.SETUP_PASSWORD, {
    email,
    password,
    token,
  });
};
