import { authClient } from '../apiClient';
import ENDPOINTS from '../endpoints';

export const refreshToken = async (): Promise<{
  accessToken: string;
  email: string;
}> => {
  const response = await authClient.post(
    ENDPOINTS.AUTH.REFRESH_TOKEN,
    {},
    { withCredentials: true },
  );
  if (!response || !response.data || !response.data.accessToken) {
    throw new Error('Invalid refresh token response');
  }
  return response.data;
};
