import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardActionArea,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { apiClient, authClient } from '../api/apiClient';
import SearchBar from '../components/SearchBar';
import { Resource } from '../core/types/resource.type';
import { useAuth } from '../auth/AuthContext';

export default function HomePage() {
  const [resources, setResources] = useState<Resource[]>([]);
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleAddClick = () => {
    navigate('/add-resources');
  };

  const handleLogout = async () => {
    try {
      await authClient.post(
        '/logout',
        {},
        {
          withCredentials: true,
        },
      );
      localStorage.removeItem('accessToken');
      document.cookie =
        'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

      logout();

      navigate('/login');
      alert('Logged out successfully!');
    } catch (error) {
      console.error('Logout failed', error);
      alert('Failed to log out.');
    }
  };

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await apiClient.get<Resource[]>('/resources/list');
        if (response.status === 200 && response.data) {
          setResources(response.data);
        } else {
          throw new Error('Failed to fetch resources');
        }
      } catch (error) {
        console.error('Error fetching resources:', error);
        alert('Failed to fetch resources.');
        setResources([]);
      }
    };

    fetchResources();
  }, []);

  const handleSearch = async (searchQuery: string) => {
    try {
      const response = await apiClient.get<Resource[]>('/resources/list', {
        params: { search: searchQuery },
      });
      if (response.status === 200 && response.data) {
        setResources(response.data);
      } else {
        throw new Error('Failed to fetch resources');
      }
    } catch (error) {
      console.error('Error fetching resources:', error);
      alert('Failed to fetch resources.');
      setResources([]);
    }
  };

  const goToAccountPage = () => {
    navigate('/account');
  };

  const goToAdminPanel = () => {
    navigate('/admin-panel');
  };

  return (
    <div>
      <h1>Home Page</h1>
      <Button variant="contained" color="primary" onClick={goToAccountPage}>
        Account
      </Button>

      {user?.role === 'admin' && (
        <Button
          key={user.id}
          variant="contained"
          style={{ marginLeft: '10px' }}
          color="primary"
          onClick={goToAdminPanel}
        >
          Admin Panel
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        style={{ marginLeft: '10px' }}
        onClick={handleAddClick}
      >
        Add Resource
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleLogout}
        style={{ marginLeft: '10px' }}
      >
        Logout
      </Button>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SearchBar
          onSearch={handleSearch}
          style={{ marginTop: '10px', width: '500px' }}
        />
        <h2>Resources</h2>
        {Array.isArray(resources) && resources.length > 0 ? (
          resources.map((resource) => (
            <Card
              key={resource.id}
              sx={{
                margin: 2,
                border: '1px solid #ccc',
                width: '500px',
              }}
            >
              <CardActionArea
                onClick={() =>
                  navigate(`/individual-resource-page/${resource.id}`)
                }
              >
                <CardContent>
                  <Typography variant="h6">{resource.title}</Typography>
                  <Typography>{resource.summary}</Typography>
                  {resource.tags && (
                    <Typography variant="body2">
                      Tags: {resource.tags.join(', ')}
                    </Typography>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          ))
        ) : (
          <Typography>No resources found.</Typography>
        )}
      </div>
    </div>
  );
}
