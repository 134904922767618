import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Box, CircularProgress } from '@mui/material';
import { apiClient } from '../api/apiClient';

interface Resource {
  title: string;
  summary: string;
  tags: string;
}

const ResourceDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [resource, setResource] = useState<Resource>({
    title: '',
    summary: '',
    tags: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResource = async () => {
      try {
        const response = await apiClient.get(`/resources/get/${id}`);
        if (response.status === 200) {
          setResource({
            title: response.data.title,
            summary: response.data.summary,
            tags: response.data.tags.join(', '),
          });
        } else {
          throw new Error('Resource not found');
        }
      } catch (error) {
        console.error('Failed to fetch resource:', error);
        alert('Failed to fetch resource details.');
      } finally {
        setLoading(false);
      }
    };

    fetchResource();
  }, [id]);

  const handleInputChange =
    (field: keyof Resource) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setResource({ ...resource, [field]: event.target.value });
    };

  const handleSubmit = async () => {
    try {
      const updatedResource = {
        ...resource,
        tags: resource.tags.split(',').map((tag) => tag.trim()),
      };
      const response = await apiClient.put(
        `/resources/update/${id}`,
        updatedResource,
      );
      if (response.status === 200) {
        setEditMode(false);
        alert('Resource updated successfully!');
      } else {
        throw new Error('Failed to update resource');
      }
    } catch (error) {
      console.error('Failed to update resource:', error);
      alert('Failed to update resource.');
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this resource?')) {
      try {
        const response = await apiClient.delete(`/resources/delete/${id}`);
        if (response.status === 204) {
          alert('Resource deleted successfully!');
          navigate('/');
        } else {
          throw new Error('Failed to delete resource');
        }
      } catch (error) {
        console.error('Error deleting resource:', error);
        alert('Failed to delete resource.');
      }
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ mt: 2, padding: 2 }}
    >
      <h1>Resource Details - ID: {id}</h1>
      <TextField
        label="Title"
        value={resource.title}
        onChange={handleInputChange('title')}
        fullWidth
        margin="normal"
        disabled={!editMode}
      />
      <TextField
        label="Summary"
        value={resource.summary}
        onChange={handleInputChange('summary')}
        fullWidth
        margin="normal"
        disabled={!editMode}
        multiline
        rows={4}
      />
      <TextField
        label="Tags"
        value={resource.tags}
        onChange={handleInputChange('tags')}
        fullWidth
        margin="normal"
        disabled={!editMode}
      />
      <Button
        onClick={() => setEditMode(!editMode)}
        color={editMode ? 'secondary' : 'primary'}
        variant="contained"
        sx={{ mt: 2, mr: 2 }}
      >
        {editMode ? 'Cancel' : 'Edit'}
      </Button>
      {editMode && (
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          sx={{ mt: 2 }}
        >
          Save Changes
        </Button>
      )}
      <Button
        onClick={handleDelete}
        color="error"
        variant="contained"
        sx={{ mt: 2, ml: 2 }}
      >
        Delete
      </Button>
    </Box>
  );
};

export default ResourceDetailsPage;
