import { apiClient } from '../apiClient';
import { ResourceStatusEnum } from '../../core/enums/resource-status.enum';
import { Resource } from '../../core/types/resource.type';

export const updateResourceStatus = async (
  resourceId: string,
  status: ResourceStatusEnum,
): Promise<Resource> => {
  try {
    const response = await apiClient.put(
      `/resources/update/${resourceId}/status`,
      { status },
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to update resource status');
    }
  } catch (error) {
    console.error('Error updating resource status:', error);
    throw error;
  }
};
