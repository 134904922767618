import { authClient } from '../apiClient';
import { User } from '../../auth/AuthContext';
import ENDPOINTS from '../endpoints';
import QUERY_KEYS from '../queryKeys';
import { useQuery } from '@tanstack/react-query';

export const getUser = async (email: string): Promise<User> => {
  const response = await authClient.get<User>(
    ENDPOINTS.USERS.GET_USER_BY_EMAIL(email),
  );
  if (!response || !response.data) {
    throw new Error('Invalid user response');
  }
  return response.data;
};

export const useGetUser = (email: string) => {
  return useQuery({
    queryKey: QUERY_KEYS.USERS.USER_BY_EMAIL(email),
    queryFn: () => getUser(email),
    enabled: !!email,
  });
};
