import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { FIVE_MINUTES_IN_MS } from '../utils/constants';

import { getUser } from '../api/queries/getUser';
import { validateToken } from '../api/queries/validateToken';
import { login as loginMutation } from '../api/mutations/login';
import { logout as logoutMutation } from '../api/mutations/logout';
import { refreshToken as refreshTokenMutation } from '../api/mutations/refreshToken';
import { setupPassword as setupPasswordMutation } from '../api/mutations/setupPassword';
import { forgotPassword as forgotPasswordMutation } from '../api/mutations/forgotPassword';

export interface User {
  id: string;
  role: string;
  name: string;
  email: string;
  bio: string;
  address: string;
  gender: string;
  profession: string;
  phoneNumber: string;
}

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  setupPassword: (
    email: string,
    password: string,
    token: string,
  ) => Promise<void>;
  validateToken: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  refreshToken: () => Promise<{ accessToken: string; email: string }>;
  getUser: (email: string) => Promise<User>;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const refreshTokenIfNeeded = async (token: string | null) => {
    if (!token) return;

    const decoded = jwtDecode(token) as { exp: number };
    const now = Date.now().valueOf() / 1000;

    if (decoded.exp < now + 5 * 60) {
      try {
        const { accessToken, email } = await refreshTokenMutation();
        localStorage.setItem('jwtToken', accessToken);
        const user = await getUser(email);
        setUser(user);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Token refresh failed:', error);
        await logout();
      }
    }
  };

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      const { accessToken } = await loginMutation(email, password);
      localStorage.setItem('jwtToken', accessToken);
      const user = await getUser(email);
      setUser(user);
      setIsAuthenticated(true);
      await refreshTokenIfNeeded(accessToken);
    } catch (error) {
      console.error('Login failed', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      await logoutMutation();
      localStorage.removeItem('jwtToken');
      document.cookie =
        'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      setIsAuthenticated(false);
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed', error);
      throw error;
    }
  };

  const validateTokenHandler = async () => {
    try {
      const response = await validateToken();
      const user = await getUser(response.email);
      setUser(user);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Token validation failed:', error);
      setIsAuthenticated(false);
      setUser(null);
      throw error;
    }
  };

  const setupPassword = async (
    email: string,
    password: string,
    token: string,
  ) => {
    setIsLoading(true);
    try {
      await setupPasswordMutation(email, password, token);
      const user = await getUser(email);
      setUser(user);
      setIsAuthenticated(true);
      await refreshTokenMutation();
      navigate('/');
    } catch (error) {
      console.error('Setup password failed:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await forgotPasswordMutation(email);
    } catch (error) {
      console.error('Forgot password request failed:', error);
      throw error;
    }
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken');
    const refreshTokenCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('refreshToken='));
    const urlParams = new URLSearchParams(window.location.search);
    const setupToken = urlParams.get('token');

    if (jwtToken) {
      validateTokenHandler().catch(() => {
        if (refreshTokenCookie) {
          refreshTokenIfNeeded(jwtToken);
        } else {
          localStorage.removeItem('jwtToken');
          navigate('/login');
        }
      });
    } else if (setupToken) {
      navigate(`/setup-password?token=${setupToken}`);
    } else {
      navigate('/login');
    }

    const tokenCheckInterval = setInterval(
      () => refreshTokenIfNeeded(localStorage.getItem('jwtToken')),
      FIVE_MINUTES_IN_MS,
    );

    return () => clearInterval(tokenCheckInterval);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        login,
        logout,
        setupPassword,
        validateToken: validateTokenHandler,
        forgotPassword,
        refreshToken: refreshTokenMutation,
        getUser,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
