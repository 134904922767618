import { authClient } from '../apiClient';
import ENDPOINTS from '../endpoints';

export const validateToken = async (): Promise<{ email: string }> => {
  const response = await authClient.get(ENDPOINTS.AUTH.VALIDATE_TOKEN, {
    withCredentials: true,
  });

  if (!response || !response.data) {
    throw new Error('Invalid token validation response');
  }
  return response.data;
};
