import { authClient } from '../apiClient';
import ENDPOINTS from '../endpoints';

export const login = async (
  email: string,
  password: string,
): Promise<{ accessToken: string }> => {
  const response = await authClient.post(ENDPOINTS.AUTH.LOGIN, {
    email,
    password,
  });
  if (!response || !response.data || !response.data.accessToken) {
    throw new Error('Invalid login response');
  }
  return response.data;
};
