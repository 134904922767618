import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Box,
  Typography,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { authClient, apiClient } from '../api/apiClient';
import { statusToEndpoint } from '../utils/constants';
import { ResourceStatusEnum } from '../core/enums/resource-status.enum';
import { Resource } from '../core/types/resource.type';
import shortenString from '../utils/shortenString';

type ResourceState = Resource[] | undefined;

export default function AdminPanel() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [resources, setResources] = useState<ResourceState>([]);
  const [changeTrigger, setChangeTrigger] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState<ResourceStatusEnum>(
    ResourceStatusEnum.INREVIEW,
  );
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openPermanentDeleteDialog, setOpenPermanentDeleteDialog] =
    useState(false);
  const [currentResourceId, setCurrentResourceId] = useState<string | null>(
    null,
  );
  const navigate = useNavigate();

  const handleInvite = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setSuccess('');

    try {
      const response = await authClient.post('/send-invite', { email });
      if (response.data.status !== 200) {
        setEmail('');
        setSuccess(`Invite sent successfully to ${email}`);
      } else {
        throw new Error('Failed to send invite');
      }
    } catch {
      setError('Failed to send invite');
    } finally {
      setLoading(false);
    }
  };

  const handleChangeResourceStatus = async (
    resourceId: string,
    newStatus: ResourceStatusEnum,
  ) => {
    setLoading(true);
    try {
      const response = await apiClient.put(
        `/resources/update/${resourceId}/status`,
        { status: newStatus },
      );

      if (response.status === 200) {
        alert(`Status changed to ${newStatus}`);
        setResources((prev) =>
          prev?.map((resource) =>
            resource.id === resourceId
              ? { ...resource, status: newStatus }
              : resource,
          ),
        );
        setChangeTrigger((prev) => prev + 1);
      } else {
        throw new Error(`Failed to change status to ${newStatus}`);
      }
    } catch (error) {
      console.error(`Error changing status to ${newStatus}:`, error);
      alert(`Failed to change status to ${newStatus}`);
    } finally {
      setLoading(false);
    }
  };

  const permanentlyDeleteResource = async (resourceId: string) => {
    setLoading(true);
    try {
      const response = await apiClient.delete(
        `/resources/delete/${resourceId}`,
      );
      if (response.status === 200 || response.status === 204) {
        alert('Resource permanently deleted');
        setResources((prev) =>
          prev?.filter((resource) => resource.id !== resourceId),
        );
      } else {
        throw new Error('Failed to permanently delete the resource');
      }
    } catch (error) {
      console.error('Error permanently deleting the resource:', error);
      alert('Failed to permanently delete the resource');
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (resourceId: string) => {
    navigate(`/individual-resource-page/${resourceId}`);
  };

  const handleOpenDialog = (
    type: 'publish' | 'delete' | 'permanentDelete',
    resourceId: string,
  ) => {
    setCurrentResourceId(resourceId);
    if (type === 'publish') setOpenPublishDialog(true);
    if (type === 'delete') setOpenDeleteDialog(true);
    if (type === 'permanentDelete') setOpenPermanentDeleteDialog(true);
  };

  const handleCloseDialog = (
    type: 'publish' | 'delete' | 'permanentDelete',
  ) => {
    if (type === 'publish') setOpenPublishDialog(false);
    if (type === 'delete') setOpenDeleteDialog(false);
    if (type === 'permanentDelete') setOpenPermanentDeleteDialog(false);
  };

  useEffect(() => {
    const fetchResources = async () => {
      const endpoint =
        statusToEndpoint[selectedStatus as keyof typeof statusToEndpoint];
      try {
        const response = await apiClient.get<Resource[]>(endpoint);
        if (response.status === 200 && response.data) {
          setResources(response.data);
        } else {
          throw new Error('Failed to fetch resources');
        }
      } catch (error) {
        console.error('Error fetching resources:', error);
        alert('Failed to fetch resources.');
        setResources([]);
      }
    };

    fetchResources();
  }, [changeTrigger, selectedStatus]);

  return (
    <Box sx={{ maxWidth: 700, mx: 'auto', my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Admin Panel
      </Typography>
      <form onSubmit={handleInvite}>
        <TextField
          label="Enter email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          variant="outlined"
          margin="normal"
          disabled={loading}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          fullWidth
          sx={{ mt: 2, mb: 2 }}
          endIcon={loading ? <CircularProgress size={24} /> : null}
        >
          {loading ? 'Sending...' : 'Invite Member'}
        </Button>
      </form>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <Box sx={{ my: 2 }}>
        <Button
          variant={
            selectedStatus === ResourceStatusEnum.INREVIEW
              ? 'contained'
              : 'outlined'
          }
          color="primary"
          onClick={() => setSelectedStatus(ResourceStatusEnum.INREVIEW)}
          sx={{ mx: 1 }}
        >
          In Review
        </Button>
        <Button
          variant={
            selectedStatus === ResourceStatusEnum.PUBLISHED
              ? 'contained'
              : 'outlined'
          }
          color="primary"
          onClick={() => setSelectedStatus(ResourceStatusEnum.PUBLISHED)}
          sx={{ mx: 1 }}
        >
          Published
        </Button>
        <Button
          variant={
            selectedStatus === ResourceStatusEnum.DELETED
              ? 'contained'
              : 'outlined'
          }
          color="primary"
          onClick={() => setSelectedStatus(ResourceStatusEnum.DELETED)}
          sx={{ mx: 1 }}
        >
          Deleted
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">Summary</TableCell>
              <TableCell align="right">Tags</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources && resources.length > 0 ? (
              resources.map((resource) => (
                <TableRow
                  key={resource.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                  }}
                  hover
                  onClick={() => handleRowClick(resource.id)}
                >
                  <TableCell component="th" scope="row">
                    {shortenString(resource.title, 20)}
                  </TableCell>
                  <TableCell align="right">
                    {shortenString(resource.summary, 50)}
                  </TableCell>
                  <TableCell align="right">
                    {resource.tags.join(', ')}
                  </TableCell>
                  <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                    {resource.status !== ResourceStatusEnum.PUBLISHED && (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{ my: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenDialog('publish', resource.id);
                        }}
                      >
                        Publish
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color={
                        resource.status === ResourceStatusEnum.DELETED
                          ? 'inherit'
                          : 'error'
                      }
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        (() => {
                          if (resource.status === ResourceStatusEnum.DELETED) {
                            handleOpenDialog('permanentDelete', resource.id);
                          } else {
                            handleOpenDialog('delete', resource.id);
                          }
                        })();
                      }}
                      sx={{ ml: 2 }}
                    >
                      {resource.status === ResourceStatusEnum.DELETED
                        ? 'Permanently Delete'
                        : 'Remove'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No resources found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openPublishDialog}
        onClose={() => handleCloseDialog('publish')}
      >
        <DialogTitle>{'Confirm Publish'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to publish this resource?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog('publish')} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleChangeResourceStatus(
                currentResourceId!,
                ResourceStatusEnum.PUBLISHED,
              );
              handleCloseDialog('publish');
            }}
            color="primary"
            autoFocus
          >
            Publish
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => handleCloseDialog('delete')}
      >
        <DialogTitle>{'Confirm Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this resource?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog('delete')} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleChangeResourceStatus(
                currentResourceId!,
                ResourceStatusEnum.DELETED,
              );
              handleCloseDialog('delete');
            }}
            color="primary"
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPermanentDeleteDialog}
        onClose={() => handleCloseDialog('permanentDelete')}
      >
        <DialogTitle>{'Confirm Permanent Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to permanently delete this resource?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseDialog('permanentDelete')}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              permanentlyDeleteResource(currentResourceId!);
              handleCloseDialog('permanentDelete');
            }}
            color="primary"
            autoFocus
          >
            Permanently Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
