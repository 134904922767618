import React, { useEffect, useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const steps = [
  'Choose Learning Goals',
  'Select Learning Style',
  'Pick Subjects',
  'Review & Confirm',
];

export default function LearningPathStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [learningGoals, setLearningGoals] = useState<string[]>([]);
  const [learningStyles, setLearningStyles] = useState<string[]>([]);
  const [subjects, setSubjects] = useState<string[]>([]);
  const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
  const [selectedStyle, setSelectedStyle] = useState<string[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get('http://localhost:3001/api/options');
        setLearningGoals(data.learningGoals || []);
        setLearningStyles(data.learningStyles || []);
        setSubjects(data.subjects || []);
      } catch (error) {
        console.error('Failed to fetch options:', error);
        setLearningGoals([]);
        setLearningStyles([]);
        setSubjects([]);
      }
    };
    fetchData();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDone = async () => {
    try {
      const response = await axios.post(
        'http://localhost:3001/api/save-learning-path',
        {
          userId: 1, // Replace with actual user ID
          learningGoals: selectedGoals,
          learningStyle: selectedStyle,
          selectedSubjects,
        },
      );
      console.log('Learning path saved successfully:', response.data);
      navigate('/');
    } catch (error) {
      console.error('Error saving learning path:', error);
    }
  };

  const handleGoalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedGoals((prev) =>
      prev.includes(value)
        ? prev.filter((goal) => goal !== value)
        : [...prev, value],
    );
  };

  const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStyle([event.target.value]);
  };

  const handleSubjectSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setSelectedSubjects((prev) =>
      prev.includes(value)
        ? prev.filter((subject) => subject !== value)
        : [...prev, value],
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <Typography>Choose Learning Goals:</Typography>
          {learningGoals.map((goal, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedGoals.includes(goal)}
                  onChange={handleGoalChange}
                  value={goal}
                />
              }
              label={goal}
            />
          ))}
        </FormControl>
      )}
      {activeStep === 1 && (
        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <Typography>Select Learning Style:</Typography>
          <RadioGroup
            value={selectedStyle[0] || ''}
            onChange={handleStyleChange}
          >
            {learningStyles.map((style, index) => (
              <FormControlLabel
                key={index}
                control={<Radio />}
                label={style}
                value={style}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      {activeStep === 2 && (
        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <Typography>Pick Subjects:</Typography>
          {subjects.map((subject, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedSubjects.includes(subject)}
                  onChange={handleSubjectSelection}
                  value={subject}
                />
              }
              label={subject}
            />
          ))}
        </FormControl>
      )}
      {activeStep === 3 && (
        <Box sx={{ mt: 2 }}>
          <Typography>Review & Confirm Your Learning Path:</Typography>
          <Typography>Goals: {selectedGoals.join(', ')}</Typography>
          <Typography>Style: {selectedStyle.join(', ')}</Typography>
          <Typography>Subjects: {selectedSubjects.join(', ')}</Typography>
          <Button onClick={handleBack} sx={{ mt: 2 }}>
            Back
          </Button>
          <Button onClick={handleDone} sx={{ mt: 2 }}>
            Done
          </Button>
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Box>
  );
}
