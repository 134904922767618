const QUERY_KEYS = {
  AUTH: {
    USER: '/user',
    TOKEN: '/token',
  },
  USERS: {
    USER_BY_EMAIL: (email: string) => ['users', email],
  },
  RESOURCES: {
    LIST_DRAFTS: '/drafts',
    UPDATE_RESOURCE_STATUS: 'updateResourceStatus',
  },
};

export default QUERY_KEYS;
